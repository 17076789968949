import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/strategy_icon.svg"

export default () => (
    <div>
        <SEO title={'ICON Worldwide | Marketing Strategy'} 
        description="We have helped some of the world’s biggest brands embrace new marketing strategy models, and we have created new brands from scratch: discover how!"
        canonical={'https://icon-worldwide.com/services/marketing-strategy'}/>
        <Header headerTitle="Marketing Strategy" headerSubTitle="is embedded in everything we produce"  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam strategy">
                <div className="text-info">
                    <p>Building “Big Ideas” demands that we dig deeper, working to <a href="https://www.designrush.com/agency/software-development/nearshore" target="_blank">understand the business</a>, the product and the ambition.
                        We work with you to translate this insight into a physical reality. We assess the challenge, the competition and
                        focus on the opportunity. We then translate that opportunity into the <a href="https://www.designrush.com/agency/mobile-app-design-development/iphone" target="_blank">creative execution</a>.
                        We understand strategy on a continuum, and are always working to help <a href="https://www.designrush.com/agency/search-engine-optimization/local-seo" target="_blank">brands evolve</a> and capture the next opportunity or conquer the next challenge.
                    </p>
                </div>
                </div>       
                <div className="winningTeam">
                <div className="text">
                    <h2>We have helped some of the world’s <span>biggest brands</span> embrace <span>new ideas.</span> And we have <span>invented new brands</span> from nothing more than the glimmer of an <span>idea.</span>
                    </h2>
                </div>
            </div>
            <div className="list-strategy">
                <h3>Brand Development</h3><i>/</i><h3>Marketing Strategy </h3><i>/</i><h3>Market Research</h3><i>/</i>
                <h3>Competitive Analysis</h3><i>/</i><h3>Target Audience Assessment</h3>
            </div>
            <div className="yellowLine"></div>
            <ServicesBottomNav/>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)